import LogoutIcon from 'assets/icons/navigation/LogoutIcon'
import MenuItem from 'shared-module/navigation/menu/MenuItem'
import useUserActions from 'user-module/user/core/useUserActions'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const LogoutMenuItem = ({ onClick }) => {
  const userActions = useUserActions()
  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on

  const logout = () => {
    onClick()
    eCommerceEnabled && localStorage.removeItem('basketId')
    userActions.logout()
  }

  return (
    <MenuItem Icon={LogoutIcon} text="menu.logout" onClick={logout} />
  )
}

export default LogoutMenuItem

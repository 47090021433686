import { useTranslation } from 'react-i18next'
import { Redirect, useLocation, Switch, Route } from 'react-router-dom'

import { Box, Container } from '@material-ui/core'
import CTabs from 'storybook-component-module/src/components/tabs/CTabs'

import cameraSharingIcon from 'assets/icons/navigation/cameraSharingIcon'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import { getCameraSharingTabs } from './CameraSharingTabsHelper'
import GuestPanel from './guest-panel/GuestPanel'
import CamerasPanel from './CamerasPanel'
import { getCurrentTabPath } from 'vosker/src/user-module/account/VoskerAccountHelpers'
import Divider from '@material-ui/core/Divider'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import cameraSharingActions from '../../core/CameraSharingActions'
import { useDispatch } from 'react-redux'

const CameraSharingPage = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const tabs = getCameraSharingTabs()
  const tabIndex = getCurrentTabPath(tabs, pathname)
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMobileResolution = useMediaQuery(theme.breakpoints.only('xs'))
  const userMembership = useUserClubMembership()

  return (
    <Box sx={{ marginTop: { xs: '103px', sm: '128px' } }} data-testid="account">
      <CAppBar Icon={cameraSharingIcon} title={t('camera_sharing.page_title')} />
      <Box>
        <Container maxWidth="lg" style={{ paddingTop: 32 }}>
          <CTabs tabs={tabs} tabIndex={tabIndex} />
        </Container>
        <Divider variant="fullWidth" />
      </Box>
      <Container maxWidth="lg" style={{ padding: isMobileResolution ? 0 : undefined }}>
        <Switch>
          <Route
            path="/camera-sharing/guests"
            render={(props) => <GuestPanel {...props} isUserClub={userMembership.isMember} />}
          />
          <Route
            path="/camera-sharing/cameras"
            render={(props) => <CamerasPanel {...props} isUserClub={userMembership.isMember} />}
          />
          <Redirect to="/camera-sharing/guests" />
        </Switch>
      </Container>
    </Box>
  )
}

export default CameraSharingPage

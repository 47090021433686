import { combineReducers } from 'redux'

const initialState = {
  ownedSharedCameras: [],
  allSharedCameras: [],
  SharedCameras: [],
  fetchingSharedCamera: true,
  pendingSharedCameras: [],
  fetchingPendingSharedCameras: true,
  sharedCameraPhotos: [],
  fetching: false,
}

const ownedSharedCameras = (state = initialState.ownedSharedCameras, action) => {
  switch (action.type) {
    case 'SET_OWNED_SHARED_CAMERAS':
      return action.cameras.data ?? []
    default:
      return state
  }
}

const fetching = (state = initialState.fetching, action) => {
  switch (action.type) {
    case 'FETCHING_OWNED_SHARED_CAMERAS':
      return false
    case 'SET_OWNED_SHARED_CAMERAS':
      return true
    default:
      return state
  }
}

const allSharedCameras = (state = initialState.allSharedCameras, action) => {
  switch (action.type) {
    case 'SET_ALL_SHARED_CAMERAS':
      return action.cameras.data
    case 'CLEAR_ALL_SHARED_CAMERAS':
      return initialState.allSharedCameras
    default:
      return state
  }
}
const SharedCameras = (state = initialState.SharedCameras, action) => {
  switch (action.type) {
    case 'SET_SHARED_CAMERAS':
      return action.cameras?.data || []
    default:
      return state
  }
}
const FetchingSharedCameras = (state = initialState.fetchingSharedCamera, action) => {
  switch (action.type) {
    case 'SET_FETCHING_SHARED_CAMERAS':
      return false
    default:
      return state
  }
}

const pendingSharedCameras = (state = initialState.pendingSharedCameras, action) => {
  switch (action.type) {
    case 'SET_PENDING_SHARED_CAMERAS':
      return action.cameras?.data || []
    default:
      return state
  }
}

const sharedCameraPhotos = (state = initialState.sharedCameraPhotos, action) => {
  switch (action.type) {
    case 'SET_SHARED_CAMERA_PHOTOS':
      return action.photos.data
    default:
      return state
  }
}

const FetchingPendingSharedCamera = (state = initialState.fetchingPendingSharedCameras, action) => {
  switch (action.type) {
    case 'SET_FETCHING_PENDING_SHARED_CAMERAS':
      return false
    default:
      return state
  }
}

export default combineReducers({
  ownedSharedCameras,
  allSharedCameras,
  SharedCameras,
  pendingSharedCameras,
  sharedCameraPhotos,
  fetching,
  FetchingSharedCameras,
  FetchingPendingSharedCamera,
})

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import cameraSharingActions from 'camera-module/camera/core/CameraSharingActions'

import CDivider from 'storybook-component-module/src/components/dividers/CDivider'
import GuestList from './GuestList'
import GuestForm from './GuestForm'
import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import Box from '@mui/material/Box'
import Divider from '@material-ui/core/Divider'
import { useTranslation } from 'react-i18next'
import useOwnedSharedCameras from '../../../core/useOwnedSharedCameras'
import PendingIcon from 'assets/icons/PendingIcon'
import AlertBox from '../AlertBox/AlertBox'
import CircleAlert from 'assets/icons/CircleAlert'
import ShareIcon from 'assets/icons/ShareIcon'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '1.875rem',
    marginTop: '3rem',
    marginBottom: '1.5rem',
    backgroundColor: theme.palette.primary.contrastText,
    minHeight: '751px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
      margin: '1.5rem 0',
    },
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
  },
  btnContainer: {
    marginRight: '24px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
    },
  },
  button: {
    padding: '7px 16px',
    gap: '10px',
    lineHeight: '14px',
    fontSize: '14px',
    borderRadius: '6px',
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    background: '#F513301A',
    marginRight: '24px',
    marginTop: '45px',
    border: '1px solid #F51330',
    padding: '12px 16px',
    borderRadius: '5px',
    marginLeft: '8px',
  },
  content: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21.46px',
    textAlign: 'left',
  },
  divider: {
    backgroundColor: theme.palette.background.tile,
    marginBottom: '1rem',
  },
  itemText: {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: '400',
    textTransform: 'capitalize',
  },
}))

const GuestPanel = ({ isUserClub = false }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const [addGuest, setAddGuest] = useState(false)
  const [selectedGuest, setSelectedGuest] = useState(null)
  const dispatch = useDispatch()
  const ownedSharedCameras = useOwnedSharedCameras()
  const isSmallResolution = useMediaQuery(theme.breakpoints.down('sm'))
  const sharedCameraLength = `${ownedSharedCameras.length} / 10`
  const formRef = useRef(null)

  useEffect(() => {
    dispatch(cameraSharingActions.fetchOwnedSharedCameras())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container data-testid="guest-panel" className={classes.root}>
      <Grid container className={classes.container}>
        <GuestList>
          <div>
            { ownedSharedCameras.length > 0 && !isUserClub && (
              <AlertBox
                text={t('camera_sharing.insiders_club_membership_expired')}
                icon={CircleAlert}
                style={{ marginBottom: '50px' }}
              />
            ) }
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              height="100%"
              style={{ marginBottom: '18px', marginTop: '24px' }}
            >
              <div data-testid="planDescriptor" className={classes.title}>{ t('camera_sharing.new_guest') }</div>
              <div className={classes.btnContainer}>
                <SecondaryButton
                  className={classes.button}
                  disabled={!isUserClub || ownedSharedCameras.length >= 10}
                  onClick={() => {
                    setAddGuest(true)
                    setSelectedGuest(null)
                    if (isSmallResolution && formRef.current) {
                      formRef.current.scrollIntoView({ behavior: 'smooth' })
                    }
                  }}
                >
                  { t('camera_sharing.add') }
                </SecondaryButton>
              </div>
            </Box>
            { ownedSharedCameras.length > 0 && (
              <>
                <Divider variant="fullWidth" className={classes.divider} />
                { ownedSharedCameras.length >= 10 && (<AlertBox text={t('camera_sharing.max_guests')} icon={CircleAlert} style={{ marginBottom: '-45px' }} />) }
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  style={{ marginBottom: '18px', marginTop: '75px' }}
                >
                  <div data-testid="planDescriptor" className={classes.title}>{ t('camera_sharing.guests_list') }</div>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{
                      marginRight: '24px',
                      fontFamily: 'oswald-regular',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '18.4px',
                      color: '#D3CFCF',
                    }}
                  ><ShareIcon /> <Box display="flex" alignItems="center" style={{ marginLeft: '8px', top: '2px', position: 'relative' }}> { sharedCameraLength }</Box>
                  </Box>
                </Box>
              </>
            ) }
            <Divider variant="fullWidth" className={classes.divider} />
            { ownedSharedCameras?.map((item) => (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  style={{ marginBottom: '18px' }}
                >
                  <div
                    data-testid="descriptor"
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={classes.itemText}
                  >
                    { item?.status === 'pending' && <PendingIcon style={{ marginRight: '10px' }} /> }
                    <span>{ item?.guestUsername }</span>
                  </div>
                  <div className={classes.btnContainer}>
                    <SecondaryButton
                      className={classes.button}
                      disabled={!isUserClub}
                      onClick={() => {
                        dispatch(cameraSharingActions.fetchOwnedSharedCameras())
                        setSelectedGuest(item)
                        if (isSmallResolution && formRef.current) {
                          formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                        }
                      }}
                    >
                      { t('camera_sharing.view') }
                    </SecondaryButton>
                  </div>
                </Box>
                <Divider variant="fullWidth" className={classes.divider} />
              </>
            )) }
          </div>
        </GuestList>

        { !isSmallResolution && <CDivider orientation="vertical" className={classes.divider} /> }
        <Grid item md={6} xs={12} sm={12} ref={formRef} style={{ paddingLeft: isSmallResolution ? '0px' : '49px', paddingRight: isSmallResolution ? '0px' : '18px', marginTop: isSmallResolution ? '30px' : undefined }}>
          <GuestForm hasGuest={ownedSharedCameras.length > 0} isUserClub={isUserClub} selectedGuest={selectedGuest} setSelectedGuest={setSelectedGuest} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default GuestPanel

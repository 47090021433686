// noinspection SpellCheckingInspection

// SLAS INFO
export const SHORT_CODE = 'lizz6yo6'
export const LOCAL_PROXY_PATH = '/sfcc'
export const REDIRECTURI_PATH = '/callback'
export const API_URL_PATH = `https://${SHORT_CODE}.api.commercecloud.salesforce.com`

export const SDB008_ORGANISATION_ID = 'f_ecom_bjvv_008'
export const DEV_ORGANISATION_ID = 'f_ecom_bjvv_dev'
export const STG_ORGANISATION_ID = 'f_ecom_bjvv_stg'
export const SDB008_PUBLIC_CLIENT_ID = '5f3558db-49e1-4f06-af29-5a8c1a6ce2d0'
export const DEV_PUBLIC_CLIENT_ID = '6467ff9b-b1c5-4073-a2b1-43238a3c9672'
export const STG_PUBLIC_CLIENT_ID = '203b6e47-5b04-4d03-bf8b-160c11c7457b'

export const orgByEnv = {
  '008': SDB008_ORGANISATION_ID,
  development: DEV_ORGANISATION_ID,
  staging: STG_ORGANISATION_ID,
}

export const clientByEnv = {
  '008': SDB008_PUBLIC_CLIENT_ID,
  development: DEV_PUBLIC_CLIENT_ID,
  staging: STG_PUBLIC_CLIENT_ID,
}

export let orgId
export const setOrgId = id => { orgId = id }

export let siteId
export const setSiteId = userCountry => {
  const countryCode = userCountry === 'ca' || userCountry === 'us' ? userCountry : 'us'

  siteId = window.location.origin.includes('400') || window.location.origin.includes('vosker')
    ? 'vosker_' + countryCode
    : 'spypoint_' + countryCode
}

export let customerId
export const setCustomerId = () => { customerId = localStorage.getItem(`customer_id_${siteId}`) }

import { CommerceApiProvider } from '@salesforce/commerce-sdk-react'

import * as config from 'ecommerce-module/config/default'
import useUser from 'user-module/user/core/useUser'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const ECommerceSessionWrapper = ({ Provider, Content, environment = process.env.NODE_ENV, ...options }) => {
  const user = useUser()
  const appOrigin = window.location.origin
  const proxyOrigin = window.location.origin.includes('localhost')
    ? window.location.origin + config.LOCAL_PROXY_PATH
    : config.API_URL_PATH

  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on

  config.setSiteId(user.country.toLowerCase())
  config.setOrgId(config.orgByEnv[environment])
  config.setCustomerId()

  const Wrapper = () => {
    return (
      <CommerceApiProvider
        shortCode={config.SHORT_CODE}
        clientId={config.clientByEnv[environment]}
        organizationId={config.orgByEnv[environment]}
        siteId={config.siteId}
        locale={user.language + '-' + user.country}
        currency={user.currencyCode}
        redirectURI={`${appOrigin}${config.REDIRECTURI_PATH}`}
        proxy={`${proxyOrigin}`}
      >
        <Content {...options} />
      </CommerceApiProvider>
    )
  }

  return eCommerceEnabled
    ? Provider
      ? <Provider><Wrapper /></Provider>
      : <Wrapper />
    : <Content {...options} />
}

export default ECommerceSessionWrapper

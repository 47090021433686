
const CamerasPanel = () => {
  return (
    <div data-testid="cameras-panel">
      { /* To be completed */ }
    </div>
  )
}

export default CamerasPanel

import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  alert: {
    display: 'flex',
    alignItems: 'center',
    background: '#F513301A',
    marginRight: '24px',
    marginTop: '45px',
    border: '1px solid #F51330',
    padding: '12px 16px',
    borderRadius: '5px',
  },
  content: {
    fontFamily: 'ibm-plex-sans-regular',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21.46px',
    textAlign: 'left',
  },
}))
const AlertBox = ({ icon: Icon, text, style }) => {
  const classes = useStyles()
  return (
    <Box className={classes.alert} style={style}>
      <span className={classes.content}>
        <Icon style={{ height: '14px', marginLeft: 9, position: 'relative', top: '2px' }} />
        { text }
      </span>
    </Box>
  )
}

export default AlertBox

import { useEffect, useState } from 'react'
import { useBasket } from '../BasketContext'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import CartDivider from 'spypoint/src/checkout-module/order/ui/cart-lines/CartDivider'
import AppBarContainer from 'shared-module/components/app-bar/AppBarContainer'
import CartSavingsLine from 'spypoint/src/checkout-module/order/ui/checkout-page/checkout-lines/CartSavingsLine'
import CartSection from 'spypoint/src/checkout-module/order/ui/cart-page/CartSection'
import DirectPurchaseWrapper from 'spypoint/src/checkout-module/order/ui/cart-page/DirectPurchaseWrapper'
import EmptyCart from 'spypoint/src/checkout-module/order/ui/cart-page/EmptyCart'
import CartButtons from 'spypoint/src/checkout-module/order/ui/cart-page/CartButtons'
import CartBanner from 'spypoint/src/checkout-module/order/ui/cart-items/CartBanner'
import LineTitle from 'spypoint/src/checkout-module/order/ui/cart-lines/LineTitle'
import Amount from 'shared-module/components/amount/Amount'
import useUser from 'user-module/user/core/useUser'
import RemoveCartItemButton from 'spypoint/src/checkout-module/order/ui/cart-page/RemoveCartItemButton'
import ItemFrequencyLine from './ItemFrequencyLine'

const BasketPage = () => {
  const { t } = useTranslation()
  const user = useUser()
  const { basket, basketDetails, fetchBasket, removeItemFromBasket } = useBasket()
  const [productItems, setProductItems] = useState(basketDetails?.productItems || [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchBasket(basket)
      } catch (err) {
        console.error(err)
      }
    }
    if (basket) {
      fetchData()
    }
  }, [basket])

  useEffect(() => {
    setProductItems(basketDetails?.productItems || [])
  }, [basketDetails])

  const handleDeleteItem = (basketId, itemId) => {
    removeItemFromBasket(basketId, itemId)
    const updatedItems = productItems.filter(item => item.itemId !== itemId)
    setProductItems(updatedItems)
  }
  const handleUpdateFrequency = async () => {
    try {
      await fetchBasket(basket)
    } catch (err) {
      console.error('Error updating basket:', err)
    }
  }
  console.log(productItems)
  return (
    <DirectPurchaseWrapper>
      <AppBarContainer Icon={ShoppingCartIcon} title={t('menu.cart')} />
      <CartBanner />
      { !productItems || productItems.length === 0
        ? (
          <EmptyCart />
          )
        : (
          <>{ basketDetails && (
            <CartSection>
              { productItems?.map((product, index) => (
                <Grid key={product.productId || index}>
                  <Grid container justifyContent="space-between" spacing={3}>
                    <LineTitle full bold>
                      { t('app:checkout_process.photo_transmission_plan.title', { planType: product.productName }) }
                    </LineTitle>
                  </Grid>
                  <Grid container justifyContent="space-between" spacing={3}>
                    <LineTitle>
                      { t('app:checkout_process.photo_transmission_plan.payment_frequency') }
                    </LineTitle>
                    <ItemFrequencyLine product={product} basket={basket} onUpdateFrequency={handleUpdateFrequency} />
                  </Grid>
                  <Grid container justifyContent="space-between" spacing={3}>
                    <LineTitle bold>{ t('app:checkout_process.price') }</LineTitle>
                    <LineTitle bold align="right">
                      <Amount value={product.price} currencyCode={user.currencyCode} />
                    </LineTitle>
                  </Grid>
                  <RemoveCartItemButton
                    productId={product.itemId}
                    onDelete={() => {
                      handleDeleteItem(basket, product.itemId)
                    }}
                  />
                  <CartDivider />
                  { product.price >= 0 && (
                    <Grid container justifyContent="space-between" spacing={3}>
                      <LineTitle bold>{ t('app:checkout_process.cart.total_before_tax').toLocaleUpperCase() }</LineTitle>
                      <LineTitle align="right" bold>
                        <Amount value={product.price} currencyCode={user.currencyCode} />
                      </LineTitle>
                    </Grid>
                  ) }
                </Grid>
              )) }
              <CartSavingsLine />
            </CartSection>
          ) }
            <CartButtons isUnavailableItemInCart={null} />
          </>
          ) }
    </DirectPurchaseWrapper>
  )
}

export default BasketPage

import { Grid, withStyles } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LineTitle from 'spypoint/src/checkout-module/order/ui/cart-lines/LineTitle'
import shopperApi from 'ecommerce-module/api/shopperApi'

const ItemFrequencyLine = ({ product, basket, onUpdateFrequency }) => {
  const { t } = useTranslation()

  const styles = theme => {
    return {
      switchBase: {
        color: theme.palette.primary.main,
        '&$checked': {
          color: theme.palette.primary.main,
        },
        '&$disabled': {
          color: theme.palette.background.paper,
        },
      },
      checked: {},
      disabled: {},
      track: {},
    }
  }
  const getPlanType = (name) => {
    if (name?.includes('Monthly')) {
      return 'month_by_month'
    } else if (name?.includes('Annual')) {
      return 'annual'
    } else {
      return 'Unknown'
    }
  }
  const FrequencySwitch = withStyles(styles)(Switch)
  const checkedState = getPlanType(product?.productName) === 'annual'
  const [checkedAnnual, setCheckedAnnual] = useState(checkedState)
  const yearly = t('app:checkout_process.photo_transmission_plan.yearly')
  const monthly = t('app:checkout_process.photo_transmission_plan.monthly')

  const newItem = product => ({
    productId: product?.id,
    quantity: product?.stepQuantity,
    price: product?.price,
    productName: product?.name,
    itemText: product?.primaryCategoryId,
  })

  const filterPlanByName = (products, productName) => {
    const product = products.find(p => p.primaryCategoryId ===
      productName)
    if (!product) {
      console.error(`Plan with name ${productName} not found`)
      return null
    }
    return product
  }

  const getPaymentFrequency = (product) => {
    const baseId = product.productId.slice(0, -1)
    const idProducts = `${baseId}0,${baseId}1`
    return shopperApi.getProductByName(idProducts)
      .then(data => {
        const paymentFrequency = !checkedAnnual ? 'data-annual-plans' : 'data-monthly-plans'
        const filteredPlan = filterPlanByName(data, paymentFrequency)
        return filteredPlan
      })
      .catch(e => {
        console.error('Error fetching products:', e)
      })
  }

  const setPaymentFrequency = (product) => {
    getPaymentFrequency(product, checkedAnnual)
      .then(filteredProduct => {
        return shopperApi
          .updateItemInBasket(basket, product.itemId, newItem(filteredProduct))
      })
      .then(() => {
        setCheckedAnnual(prev => !prev)
        onUpdateFrequency()
      })
  }

  return (
    product
      ? (
        <Typography
          variant="body2"
          component="div"
          align="right"
          style={{ padding: '0px 10px' }}
        >
          <Grid component="label" container alignItems="center">
            <Grid item>{ monthly }</Grid>
            <Grid item>
              <FrequencySwitch
                checked={checkedAnnual}
                onChange={() => setPaymentFrequency(product)}
              />
            </Grid>
            <Grid item>{ yearly }</Grid>
          </Grid>
        </Typography>
        )
      : (
        <LineTitle align="right"> { checkedState
          ? yearly
          : monthly }
        </LineTitle>
        )
  )
}

export default ItemFrequencyLine
